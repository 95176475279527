import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { api } from '../services.js'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pagamentoLoading: false,
    modalCheckout: false,
    cart: [],
    weather: {},
    dataIsRecived: false,
    logged: false,
    position: {
      lat: "",
      lon: "",
    },
    calendarOpts: {
      pt: {
        firstDayOfWeek: 0,
        dayNames: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
        monthNamesShort: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
        dateFormat: "mm/dd/yy",
        weekHeader: "Wk",
      },
    },
    produto: {
      nome: "",
      id: "",
    },
    user: {
     
    }
  },
  mutations: {
    
  },
  actions: {
  },
  modules: {},
  getters: {
    getUsuario: (state) => {
      return state.userDetails;
    },
    getCart: (state) => {
      return state.cart;
    },
  },
});
