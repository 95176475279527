<template>
  <div id="app">
    <transition mode="out-in">
      <router-view />
    </transition>
        <div class="block-ui" v-show="$store.state.pagamentoLoading" style="z-index: 9999">
      <div>
        <i class="material-icons text-white text-center d-flex justify-content-center cloud">sentiment_satisfied_alt</i>
        <h1 class="text-white text-center">Aguarde, processando seu pagamento.</h1>
        <loading color="#fff" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  computed: {
    storeCart() {
    },
  },
  created() {
  },
};
</script>
<style scoped>
  body{
    background: none !important;
    background-color: none !important;
  }
</style>